import React, { lazy, Suspense, useCallback } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

// Common Style
import "./styles/reset.scss";
import "./styles/popup.scss";
import "./styles/common.scss";

const Template = lazy(() => import("./pages/template"));
const Index2 = lazy(() => import("./pages/index2"));
const Index = lazy(() => import("./pages/index"));
// 메모
const MemoList = lazy(() => import("./pages/memo/memo_list"));
const MemoReg = lazy(() => import("./pages/memo/memo_reg"));
const MemoDetail = lazy(() => import("./pages/memo/memo_detail"));
// 개인정보
const Privacy = lazy(() => import("./pages/privacy/index"));
// 상담코드
const Consulting = lazy(() => import("./pages/consulting"));
// 알림
const Alarm = lazy(() => import("./pages/alarm"));
// 로그인
const Login = lazy(() => import("./pages/join/login"));
// 비밀번호 찾기 / 재설정
const FindPassword = lazy(() => import("./pages/join/find_password"));
const ResetPassword = lazy(() => import("./pages/join/reset_password"));
// 스케줄
const ScheduleRegPrivate = lazy(
  () => import("./pages/schedule/schedule_reg_private")
);
const ScheduleRegOt = lazy(() => import("./pages/schedule/schedule_reg_ot"));
const ScheduleRegConsult = lazy(
  () => import("./pages/schedule/schedule_reg_consult")
);
const ScheduleRegMeals = lazy(
  () => import("./pages/schedule/schedule_reg_meals")
);
const ScheduleRegEtc = lazy(() => import("./pages/schedule/schedule_reg_etc"));
const ScheduleCurWeek = lazy(
  () => import("./pages/schedule/schedule_cur_week")
);
const ScheduleCurDay = lazy(() => import("./pages/schedule/schedule_cur_day"));
const ScheduleReservation = lazy(
  () => import("./pages/schedule/schedule_reservation")
);
const ScheduleDetailPrivate1 = lazy(
  () => import("./pages/schedule/schedule_detail_private1")
);
const ScheduleDetailPrivate2 = lazy(
  () => import("./pages/schedule/schedule_detail_private2")
);
const AttendManage1 = lazy(() => import("./pages/schedule/attend_manage1"));
const AttendManage2 = lazy(() => import("./pages/schedule/attend_manage2"));
const ScheduleDetailOt1 = lazy(
  () => import("./pages/schedule/schedule_detail_ot1")
);
const ScheduleDetailOt2 = lazy(
  () => import("./pages/schedule/schedule_detail_ot2")
);
const ScheduleDetailAd = lazy(
  () => import("./pages/schedule/schedule_detail_ad")
);
const ScheduleDetailGroup = lazy(
  () => import("./pages/schedule/schedule_detail_group")
);
// 지점선택
const BranchChoice = lazy(() => import("./pages/join/branch_choice"));
// 업무
const Work = lazy(() => import("./pages/work/index"));
const WorkReg1 = lazy(() => import("./pages/work/work_reg1"));
const WorkReg2 = lazy(() => import("./pages/work/work_reg2"));
const WorkReg3 = lazy(() => import("./pages/work/work_reg3"));
const WorkReg4 = lazy(() => import("./pages/work/work_reg4"));
const WorkReg5 = lazy(() => import("./pages/work/work_reg5"));
const WorkReg6 = lazy(() => import("./pages/work/work_reg6"));
const WorkDetail1 = lazy(() => import("./pages/work/work_detail1"));
const WorkDetail2 = lazy(() => import("./pages/work/work_detail2"));
const WorkDetail3 = lazy(() => import("./pages/work/work_detail3"));
const WorkConfirm = lazy(() => import("./pages/work/work_confirm"));
const WorkConfirmDetail1 = lazy(
  () => import("./pages/work/work_confirm_detail1")
);
const WorkConfirmDetail2 = lazy(
  () => import("./pages/work/work_confirm_detail2")
);
const WorkConfirmDetail3 = lazy(
  () => import("./pages/work/work_confirm_detail3")
);
const WorkConfirmDetail4 = lazy(
  () => import("./pages/work/work_confirm_detail4")
);
const WorkConfirmDetail5 = lazy(
  () => import("./pages/work/work_confirm_detail5")
);
const WorkConfirmDetail6 = lazy(
  () => import("./pages/work/work_confirm_detail6")
);
const BranchManage = lazy(() => import("./pages/work/branch_manage"));
const BranchManageReg = lazy(() => import("./pages/work/branch_manage_reg"));
const BranchManageDetail = lazy(
  () => import("./pages/work/branch_manage_detail")
);
const Authorization = lazy(() => import("./pages/work/authorization"));
const AuthorizationDetail1 = lazy(
  () => import("./pages/work/authorization_detail1")
);
const AuthorizationDetail2 = lazy(
  () => import("./pages/work/authorization_detail2")
);
const AuthorizationDetail3 = lazy(
  () => import("./pages/work/authorization_detail3")
);
const AuthorizationWrite = lazy(
  () => import("./pages/work/authorization_write")
);
const AuthorizationReg1 = lazy(() => import("./pages/work/authorization_reg1"));
const AuthorizationReg2 = lazy(() => import("./pages/work/authorization_reg2"));
const AuthorizationReg3 = lazy(() => import("./pages/work/authorization_reg3"));
const WorkReview = lazy(() => import("./pages/work/review"));
const PaymentSummary = lazy(() => import("./pages/work/payment_summary"));
const PaymentUpload = lazy(() => import("./pages/work/payment_upload"));

// 회원
const MemberList = lazy(() => import("./pages/member/member_list"));
const ProductPay1 = lazy(() => import("./pages/member/product_pay1"));
const ProductPay2 = lazy(() => import("./pages/member/product_pay2"));
const ProductPay3 = lazy(() => import("./pages/member/product_pay3"));
const ProductPay4 = lazy(() => import("./pages/member/product_pay4"));
const ProductPay5 = lazy(() => import("./pages/member/product_pay5"));
const ProductPay5Real = lazy(() => import("./pages/member/product_pay5_real"));
const ProductPay6 = lazy(() => import("./pages/member/product_pay6"));
const MemberDetail = lazy(() => import("./pages/member/member_detail"));
const MemberDetailProfile = lazy(
  () => import("./pages/member/member_detail_profile")
);
const MemberLocker = lazy(() => import("./pages/member/member_locker"));
const Locker = lazy(() => import("./pages/member/locker"));
const WearableRental = lazy(() => import("./pages/member/wearable_rental"));
const WearableManage = lazy(() => import("./pages/member/wearable_manage"));

// 급여
const Pay = lazy(() => import("./pages/pay/index"));
const PayDetail = lazy(() => import("./pages/pay/pay_detail"));
const PayPerformance = lazy(() => import("./pages/pay/pay_performance"));

// 랭킹
const Ranking = lazy(() => import("./pages/ranking/index"));
const RankingBranch = lazy(() => import("./pages/ranking/ranking_branch"));
const RankingStaff = lazy(() => import("./pages/ranking/ranking_staff"));

// 영업
const Tm = lazy(() => import("./pages/sales/index"));
const TmDetail = lazy(() => import("./pages/sales/tm_detail"));
const TmResult = lazy(() => import("./pages/sales/tm_result"));
const TmResultDetail = lazy(() => import("./pages/sales/tm_result_detail"));
const TeacherHold = lazy(() => import("./pages/sales/teacher_hold"));
const TeacherHoldDetail = lazy(
  () => import("./pages/sales/teacher_hold_detail")
);
const TeacherResult = lazy(() => import("./pages/sales/teacher_result"));
const TeacherResultDetail = lazy(
  () => import("./pages/sales/teacher_result_detail")
);
const SaleMember = lazy(() => import("./pages/sales/member"));
const SaleMemberDetail = lazy(() => import("./pages/sales/member_detail"));
const SaleCustomer = lazy(() => import("./pages/sales/customer"));
const SaleCustomerDetail = lazy(() => import("./pages/sales/customer_detail"));

// 공지사항
const Notice = lazy(() => import("./pages/notice/index"));
const NoticeDetail = lazy(() => import("./pages/notice/notice_detail"));

function Router() {
  return (
    <Suspense fallback="loading...">
      <BrowserRouter>
        <Routes>
          {/* 컴포넌트 템플릿 */}
          <Route path="/template" element={<Template />} />
          {/* 리스트업 */}
          <Route path="/index2" element={<Index2 />} />
          {/* 메인 */}
          <Route path="/" element={<Index />} />
          {/* 메모 */}
          <Route path="/memo/memo_list" element={<MemoList />} />
          <Route path="/memo/memo_reg" element={<MemoReg />} />
          <Route path="/memo/memo_detail" element={<MemoDetail />} />
          {/* 개인정보 */}
          <Route path="/privacy/" element={<Privacy />} />
          {/* 상담코드 */}
          <Route path="/consulting/" element={<Consulting />} />
          {/* 알림 */}
          <Route path="/alarm/" element={<Alarm />} />
          {/* 로그인 */}
          <Route path="/join/login" element={<Login />} />
          {/* 비밀번호 찾기 / 재설정 */}
          <Route path="/join/find_password" element={<FindPassword />} />
          <Route path="/join/reset_password" element={<ResetPassword />} />
          {/* 스케줄 */}
          <Route
            path="/schedule/schedule_reg_private"
            element={<ScheduleRegPrivate />}
          />
          <Route path="/schedule/schedule_reg_ot" element={<ScheduleRegOt />} />
          <Route
            path="/schedule/schedule_reg_consult"
            element={<ScheduleRegConsult />}
          />
          <Route
            path="/schedule/schedule_reg_meals"
            element={<ScheduleRegMeals />}
          />
          <Route
            path="/schedule/schedule_reg_etc"
            element={<ScheduleRegEtc />}
          />
          <Route
            path="/schedule/schedule_cur_week"
            element={<ScheduleCurWeek />}
          />
          <Route
            path="/schedule/schedule_cur_day"
            element={<ScheduleCurDay />}
          />
          <Route
            path="/schedule/schedule_reservation"
            element={<ScheduleReservation />}
          />
          <Route
            path="/schedule/schedule_detail_private1"
            element={<ScheduleDetailPrivate1 />}
          />
          <Route
            path="/schedule/schedule_detail_private2"
            element={<ScheduleDetailPrivate2 />}
          />
          <Route path="/schedule/attend_manage1" element={<AttendManage1 />} />
          <Route path="/schedule/attend_manage2" element={<AttendManage2 />} />
          <Route
            path="/schedule/schedule_detail_ot1"
            element={<ScheduleDetailOt1 />}
          />
          <Route
            path="/schedule/schedule_detail_ot2"
            element={<ScheduleDetailOt2 />}
          />
          <Route
            path="/schedule/schedule_detail_ad"
            element={<ScheduleDetailAd />}
          />
          <Route
            path="/schedule/schedule_detail_group"
            element={<ScheduleDetailGroup />}
          />
          {/* 지점선택 */}
          <Route path="/join/branch_choice" element={<BranchChoice />} />
          {/* 업무 */}
          <Route path="/work/" element={<Work />} />
          <Route path="/work/work_reg1" element={<WorkReg1 />} />
          <Route path="/work/work_reg2" element={<WorkReg2 />} />
          <Route path="/work/work_reg3" element={<WorkReg3 />} />
          <Route path="/work/work_reg4" element={<WorkReg4 />} />
          <Route path="/work/work_reg5" element={<WorkReg5 />} />
          <Route path="/work/work_reg6" element={<WorkReg6 />} />
          <Route path="/work/work_detail1" element={<WorkDetail1 />} />
          <Route path="/work/work_detail2" element={<WorkDetail2 />} />
          <Route path="/work/work_detail3" element={<WorkDetail3 />} />
          <Route path="/work/work_confirm" element={<WorkConfirm />} />
          <Route
            path="/work/work_confirm_detail1"
            element={<WorkConfirmDetail1 />}
          />
          <Route
            path="/work/work_confirm_detail2"
            element={<WorkConfirmDetail2 />}
          />
          <Route
            path="/work/work_confirm_detail3"
            element={<WorkConfirmDetail3 />}
          />
          <Route
            path="/work/work_confirm_detail4"
            element={<WorkConfirmDetail4 />}
          />
          <Route
            path="/work/work_confirm_detail5"
            element={<WorkConfirmDetail5 />}
          />
          <Route
            path="/work/work_confirm_detail6"
            element={<WorkConfirmDetail6 />}
          />
          <Route path="/work/branch_manage" element={<BranchManage />} />
          <Route path="/work/branch_manage_reg" element={<BranchManageReg />} />
          <Route
            path="/work/branch_manage_detail"
            element={<BranchManageDetail />}
          />
          <Route path="/work/authorization" element={<Authorization />} />
          <Route
            path="/work/authorization_detail1"
            element={<AuthorizationDetail1 />}
          />
          <Route
            path="/work/authorization_detail2"
            element={<AuthorizationDetail2 />}
          />
          <Route
            path="/work/authorization_detail3"
            element={<AuthorizationDetail3 />}
          />
          <Route
            path="/work/authorization_write"
            element={<AuthorizationWrite />}
          />
          <Route
            path="/work/authorization_reg1"
            element={<AuthorizationReg1 />}
          />
          <Route
            path="/work/authorization_reg2"
            element={<AuthorizationReg2 />}
          />
          <Route
            path="/work/authorization_reg3"
            element={<AuthorizationReg3 />}
          />
          <Route path="/work/review" element={<WorkReview />} />
          <Route path="/work/payment_summary" element={<PaymentSummary />} />
          <Route path="/work/payment_upload" element={<PaymentUpload />} />
          {/* 회원 */}
          <Route path="/member/member_list" element={<MemberList />} />
          <Route path="/member/product_pay1" element={<ProductPay1 />} />
          <Route path="/member/product_pay2" element={<ProductPay2 />} />
          <Route path="/member/product_pay3" element={<ProductPay3 />} />
          <Route path="/member/product_pay4" element={<ProductPay4 />} />
          <Route path="/member/product_pay5" element={<ProductPay5 />} />
          <Route
            path="/member/product_pay5_real"
            element={<ProductPay5Real />}
          />
          <Route path="/member/product_pay6" element={<ProductPay6 />} />
          <Route path="/member/member_detail" element={<MemberDetail />} />
          <Route
            path="/member/member_detail_profile"
            element={<MemberDetailProfile />}
          />
          <Route path="/member/member_locker" element={<MemberLocker />} />
          <Route path="/member/locker" element={<Locker />} />
          <Route path="/member/wearable_rental" element={<WearableRental />} />
          <Route path="/member/wearable_manage" element={<WearableManage />} />
          {/* 급여 */}
          <Route path="/pay/" element={<Pay />} />
          <Route path="/pay/pay_detail" element={<PayDetail />} />
          <Route path="/pay/pay_performance" element={<PayPerformance />} />
          {/* 랭킹 */}
          <Route path="/ranking/" element={<Ranking />} />
          <Route path="/ranking/ranking_branch" element={<RankingBranch />} />
          <Route path="/ranking/ranking_staff" element={<RankingStaff />} />
          {/* 영업 */}
          <Route path="/sales/" element={<Tm />} />
          <Route path="/sales/tm_detail" element={<TmDetail />} />
          <Route path="/sales/tm_result" element={<TmResult />} />
          <Route path="/sales/tm_result_detail" element={<TmResultDetail />} />
          <Route path="/sales/teacher_hold" element={<TeacherHold />} />
          <Route
            path="/sales/teacher_hold_detail"
            element={<TeacherHoldDetail />}
          />
          <Route path="/sales/teacher_result" element={<TeacherResult />} />
          <Route
            path="/sales/teacher_result_detail"
            element={<TeacherResultDetail />}
          />
          <Route path="/sales/member" element={<SaleMember />} />
          <Route path="/sales/member_detail" element={<SaleMemberDetail />} />
          <Route path="/sales/customer" element={<SaleCustomer />} />
          <Route
            path="/sales/customer_detail"
            element={<SaleCustomerDetail />}
          />
          {/* 공지사항 */}
          <Route path="/notice/" element={<Notice />} />
          <Route path="/notice/notice_detail" element={<NoticeDetail />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
